// eslint-disable-next-line no-restricted-imports
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn'; // 导入中文语言包
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { nbsp } from 'lib/html-entities';

const relativeTimeConfig = {
  thresholds: [
    { l: 's', r: 1 },
    { l: 'ss', r: 59, d: 'second' },
    { l: 'm', r: 1 },
    { l: 'mm', r: 59, d: 'minute' },
    { l: 'h', r: 1 },
    { l: 'hh', r: 23, d: 'hour' },
    { l: 'd', r: 1 },
    { l: 'dd', r: 6, d: 'day' },
    { l: 'w', r: 1 },
    { l: 'ww', r: 4, d: 'week' },
    { l: 'M', r: 1 },
    { l: 'MM', r: 11, d: 'month' },
    { l: 'y', r: 17 },
    { l: 'yy', d: 'year' },
  ],
};

dayjs.extend(relativeTime, relativeTimeConfig);
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(weekOfYear);
dayjs.extend(minMax);

dayjs.locale('zh-cn');

dayjs.updateLocale('zh-cn', {
  formats: {
    llll: `YYYY年M月D日 HH:mm:ss (Z${ nbsp }UTC)`,
    lll: 'YYYY MMM DD, h:mm A',
  },
  relativeTime: {
    s: '1秒',
    ss: '%d秒',
    future: '%s内',
    past: '%s前',
    m: '1分钟',
    mm: '%d分钟',
    h: '1小时',
    hh: '%d小时',
    d: '1天',
    dd: '%d天',
    w: '1周',
    ww: '%d周',
    M: '1月',
    MM: '%d月',
    y: '1年',
    yy: '%d年',
  },
});

export default dayjs;
